/**
* api接口统一管理
*/
import { axios } from '@/utils/request'

export const listFinanceReceipt = params => axios({
    url: '/api/customer/customer/financeReceipt/list',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const addFinanceReceipt = params => axios({
    url: '/api/customer/customer/financeReceipt/add',
    method: 'post',
    data: JSON.stringify(params),
    headers: {

        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const editFinanceReceipt = params => axios({
    url: '/api/feedback/system/financeReceipt/edit',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const delFinanceReceipt = params => axios({
    url:'/api/feedback/system/financeReceipt/del/' + params,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const selectByIdFinanceReceipt = params => axios({
    url: '/api/feedback/system/financeReceipt/selectById/' + params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})


export const downExcel = params => axios({
  url: '/api/customer/customer/financeReceipt/excel',
  method: 'post',
  responseType: 'blob',
  params,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
})

export const selectByOrderCodeFinanceReceipt = params => axios({
  url: '/api/dealer/dealer/dealerPaymentFrom/selectByOrderCode/' + params,
  method: 'get',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
})

export const listLog = params => axios({
  url: '/api/dealer/dealer/paymentFromTrackLog/list',
  method: 'get',
  params,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
})



